import React, { useState, useEffect, useCallback } from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import * as yup from "yup"
import axios from "axios"
import { Modal, Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

import data from "../../../components/data/diagnosis"

const Form = () => {
    const [open, setOpen] = useState(false)

    // toast
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarType, setSnackbarType] = useState("info")

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setSnackbarOpen(false)
    }

    const [formSchema, setFormSchema] = useState({})

    const handleFillSchema = useCallback(() => {
        const schema = {}
        data.questions.forEach(item => {
            let validation = yup[item.type]()
            if (item.validation_type) {
                validation = validation[item.validation_type](
                    item.validation_text
                )
            }

            schema[item.id] = validation
                .typeError("Preencha com um valor válido")
                .required("Preencha o campo")
        })
        setFormSchema(yup.object().shape(schema))
    }, [setFormSchema])

    useEffect(() => {
        handleFillSchema()
    }, [handleFillSchema])

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema),
    })

    const handleClose = () => {
        setOpen(false)
    }

    const onSubmit = values => {
        setSnackbarOpen(true)
        axios
            .post("https://adac.dev/partners/sowx/newsletter-api/form", values)
            .then(() => {
                setSnackbarType("success")
            })
            .catch(err => {
                console.log(err)
                setSnackbarType("error")
            })
            .finally(() => {
                // setSnackbarOpen(false);
            })
    }

    const SnackBar = () => {
        const Alert = props => (
            <MuiAlert elevation={6} variant="filled" {...props} />
        )

        let text
        switch (snackbarType) {
            case "success":
                text =
                    "Sua mensagem foi enviada! Entraremos em contato o mais rápido possível."
                break
            case "error":
                text = "Opa, algo deu errado! :("
                break
            case "info":
                text = "Estamos processando, por favor aguarde."
                break
            default:
        }

        return (
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                    {text}
                </Alert>
            </Snackbar>
        )
    }

    return (
        <div className="wrapper">
            <div className="form__out">
                <form
                    className="wrapper-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {data.questions.map((item, index) => (
                        <div className="box__input" key={`${item.id}-${index}`}>
                            <span className="title bold">{item.question}</span>
                            <Controller
                                as={<input aria-label="input" />}
                                className="form-name"
                                placeholder={item.question}
                                type={item.type}
                                name={item.id}
                                control={control}
                                defaultValue=""
                            />
                            {errors[item.id] && (
                                <span className="error">
                                    {errors[item.id].message}
                                </span>
                            )}
                        </div>
                    ))}
                    <div>
                        <button type="submit" className="button">
                            ENVIAR
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div id="contato-modal">
                    <h2>Sua mensagem foi enviada!</h2>
                    <p>Logo nossa equipe entrará em contato com você.</p>
                </div>
            </Modal>
            <SnackBar />
        </div>
    )
}

export default Form
